import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';

const AlertPage = React.lazy(() => import('pages/AlertPage'));
const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
const BadgePage = React.lazy(() => import('pages/BadgePage'));
const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
const ButtonPage = React.lazy(() => import('pages/ButtonPage'));
const CardPage = React.lazy(() => import('pages/CardPage'));
const ChartPage = React.lazy(() => import('pages/ChartPage'));
// const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const DashboardPage = React.lazy(() => import('./pages/Dashboard'));
const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
const FormPage = React.lazy(() => import('pages/FormPage'));
const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
const ModalPage = React.lazy(() => import('pages/ModalPage'));
const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
const TablePage = React.lazy(() => import('pages/TablePage'));
const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
const WidgetPage = React.lazy(() => import('pages/WidgetPage'));

const FinishTypeForm = React.lazy(() => import('./views/FinishType/Form'))
const FinishListing = React.lazy(() => import('./views/FinishType/List'))
const MetalTypeForm = React.lazy(() => import('./views/MetalType/Form'))
const MetalTypeListing = React.lazy(() => import('./views/MetalType/List'))
const ProductForm = React.lazy(() => import('./views/Products/Form'))
const ProductListing = React.lazy(() => import('./views/Products/List'))

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {

  adminRedirect = (props, Component) => {
    const AuthUser = localStorage.getItem('token');
    const expiry = localStorage.getItem('expiry');
    if (AuthUser && new Date(new Date().toUTCString()).valueOf() < new Date(expiry).valueOf()) {
      // if (AuthUser) {
      return <Component {...props} />
    } else {
      // whenever redirecting, please clear localstorage
      localStorage.clear()
      return <Redirect from="/" to="/login" />
    }
  }

  render() {
    return (
      <BrowserRouter>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} />
              )}
            />

            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>
                <Route exact path="/" render={props => this.adminRedirect(props, DashboardPage)} />

                <Route exact path="/login-modal" render={props => this.adminRedirect(props, AuthModalPage)} />
                <Route exact path="/buttons" render={props => this.adminRedirect(props, ButtonPage)} />
                <Route exact path="/cards" render={props => this.adminRedirect(props, CardPage)} />
                <Route exact path="/widgets" render={props => this.adminRedirect(props, WidgetPage)} />
                <Route exact path="/typography" render={props => this.adminRedirect(props, TypographyPage)} />
                <Route exact path="/alerts" render={props => this.adminRedirect(props, AlertPage)} />
                <Route exact path="/tables" render={props => this.adminRedirect(props, TablePage)} />
                <Route exact path="/badges" render={props => this.adminRedirect(props, BadgePage)} />
                <Route
                  exact
                  path="/button-groups"
                  component={ButtonGroupPage}
                  render={props => this.adminRedirect(props, DashboardPage)}
                />
                <Route exact path="/dropdowns" render={props => this.adminRedirect(props, DropdownPage)} />
                <Route exact path="/progress" render={props => this.adminRedirect(props, ProgressPage)} />
                <Route exact path="/modals" render={props => this.adminRedirect(props, ModalPage)} />
                <Route exact path="/forms" render={props => this.adminRedirect(props, FormPage)} />
                <Route exact path="/input-groups" render={props => this.adminRedirect(props, InputGroupPage)} />
                <Route exact path="/charts" render={props => this.adminRedirect(props, ChartPage)} />

                <Route exact path="/products/list" render={props => this.adminRedirect(props, ProductListing)} />
                <Route exact path="/products/form" render={props => this.adminRedirect(props, ProductForm)} />
                <Route exact path="/finish/list" render={props => this.adminRedirect(props, FinishListing)} />
                <Route exact path="/finish/form" render={props => this.adminRedirect(props, FinishTypeForm)} />
                <Route exact path="/metal/list" render={props => this.adminRedirect(props, MetalTypeListing)} />
                <Route exact path="/metal/form" render={props => this.adminRedirect(props, MetalTypeForm)} />

              </React.Suspense>
            </MainLayout>
            <Redirect to="/" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
