const token = () => `${localStorage.getItem("token")}`

export const jsonHeaders = () => {
  let generatedHeaders = {
    "Content-Type": "application/json"
  }
  if (token()) {
    generatedHeaders = {
      ...generatedHeaders,
      'Authorization': `${token()}`
    }
  }
  return generatedHeaders
}
