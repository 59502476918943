import axios from 'axios'
import {
  refreshLoginService
} from '../services/api'

const axiosApiInstance = axios.create();

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  return new Promise((res, rej) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      refreshLoginService({ refreshToken: localStorage.getItem('refreshToken') })
        .then(async (data) => {
          if (data.status === 200) {
            originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
            const resp = await axiosApiInstance(originalRequest)
            res(resp)
          }
        })
        .catch(e => {
          rej(e)
        })
    }
  })
});

export default axiosApiInstance
