import { Button } from 'reactstrap';
import moment from 'moment';
import React from 'react'

// export const BASE_URL = 'http://localhost:5050'
export const BASE_URL = 'https://admin-api.aglowexports.com'

export const NOTIFICATION_SYSTEM_STYLE = {
  NotificationItem: {
    DefaultStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      borderRadius: '4px',
      fontSize: '14px',
    },

    success: {
      borderTop: 0,
      backgroundColor: '#45b649',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    error: {
      borderTop: 0,
      backgroundColor: '#f85032',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    warning: {
      borderTop: 0,
      backgroundColor: '#ffd700',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    info: {
      borderTop: 0,
      background: 'linear-gradient(to right, #6a82fb, #fc5c7d)',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },
  },

  Title: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
      paddingRight: 5,
      color: '#fff',
      display: 'inline-flex',
      fontSize: 20,
      fontWeight: 'bold',
      // left: '15px',
      // position: 'absolute',
      // top: '50%',
    },
  },

  MessageWrapper: {
    DefaultStyle: {
      display: 'block',
      color: '#fff',
      width: '100%',
    },
  },

  Dismiss: {
    DefaultStyle: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'inherit',
      fontSize: 20,
      color: '#f2f2f2',
      position: 'relative',
      margin: 0,
      padding: 0,
      background: 'none',
      borderRadius: 0,
      opacity: 1,
      width: 20,
      height: 20,
      textAlign: 'initial',
      float: 'none',
      top: 'unset',
      right: 'unset',
      lineHeight: 'inherit',
    },
  },

  Action: {
    DefaultStyle: {
      background: '#fff',
      borderRadius: '2px',
      padding: '6px 20px',
      fontWeight: 'bold',
      margin: '10px 0 0 0',
      border: 0,
    },

    success: {
      backgroundColor: '#45b649',
      color: '#fff',
    },

    error: {
      backgroundColor: '#f85032',
      color: '#fff',
    },

    warning: {
      backgroundColor: '#ffd700',
      color: '#fff',
    },

    info: {
      backgroundColor: '#00c9ff',
      color: '#fff',
    },
  },

  ActionWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
    },
  },
};


export const FinishColumns = (callback) => {
  return [
    {
      name: 'ID',
      selector: '_id',
      sortable: true,
    },
    {
      name: 'Name',
      selector: '_title',
      sortable: true,
    },
    {
      name: 'Image',
      selector: '_path',
      sortable: false,
      cell: row => {
        return row._path ? (
          <img height="56px" width="84px" style={{ padding: '5px' }} alt={row.name} src={`${BASE_URL}/public/${row._path}`} onClick={() => callback('preview', row)} />
        ) : 'No Image'
      }
    },
    {
      name: 'Added on',
      selector: 'createdAt',
      sortable: true,
      cell: row => <span>{moment(row.createdAt).format('LL')}</span>
    },
    {
      name: 'Updated on',
      selector: 'updatedAt',
      sortable: true,
      cell: row => <span>{moment(row.updatedAt).format('LL')}</span>
    },
    {
      name: 'Action',
      sortable: false,
      cell: row => (
        <>
          <Button color="primary" onClick={() => callback('edit', row)}>Edit</Button>
          &nbsp;
          <Button color="danger" onClick={() => callback('delete', row)}>Delete</Button>
        </>
      )
    }
  ]
}

export const ProductColumns = (callback) => {
  return [
    {
      name: 'ID',
      selector: '_id',
      sortable: true,
    },
    {
      name: 'Name',
      selector: '_title',
      sortable: true,
    },
    {
      name: 'Image',
      selector: '_path',
      sortable: false,
      cell: row => {
        return row._path ? (
          <img height="56px" width="84px" style={{ padding: '5px' }} alt={row.name} src={`${BASE_URL}/public/${row._path}`} onClick={() => callback('preview', row)} />
        ) : 'No Image'
      }
    },
    {
      name: 'Item Code',
      selector: '_title',
      sortable: true,
      cell: row => (
        <div>
          <div>{row._itemcode}</div>
          <div>{row._sizetypesmall}</div>
          <div>{row._sizetypemedium}</div>
          <div>{row._sizetypelarge}</div>
        </div>
      )
    },
    {
      name: 'Commodity',
      selector: '_title',
      sortable: true,
      cell: row => (
        <div>
          {
            row.metal.map(r => (
              <><div>{r._title}</div></>
            ))
          }
        </div>
      )
    },
    {
      name: 'Finish',
      selector: '_title',
      sortable: true,
      cell: row => (
        <div>
          {
            row.metal.map(r => (
              <><div>{r._title}</div></>
            ))
          }
        </div>
      )
    },
    {
      name: 'Added on',
      selector: 'createdAt',
      sortable: true,
      cell: row => <div>{moment(row.createdAt).format('LL')}</div>
    },
    {
      name: 'Action',
      sortable: false,
      cell: row => (
        <>
          <Button color="primary" onClick={() => callback('edit', row)}>Edit</Button>
          &nbsp;
          <Button color="danger" onClick={() => callback('delete', row)}>Delete</Button>
        </>
      )
    }
  ]
}
