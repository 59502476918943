import axios from '../utils/axios'
import {
  jsonHeaders
} from '../helper'

// const baseUrl = `http://localhost:5050/api/v1/`
const baseUrl = `https://admin-api.aglowexports.com/api/v1/`

export const loginService = async (data) => {
  return await axios({
    method: 'POST',
    url: `${baseUrl}login`,
    headers: jsonHeaders(),
    data
  })
}

export const refreshLoginService = async (data) => {
  return new Promise((res, rej) => {
    axios({
      method: 'POST',
      url: `${baseUrl}refreshLogin`,
      data
    })
      .then(({ data }) => {
        console.log('data', data)
        if (data && data.status === 200 && data.message) {
          localStorage.setItem('token', data.message.token)
          localStorage.setItem('refreshToken', data.message.refreshToken)
          res(data)
        } else {
          rej({
            'error': data.message
          })
        }
      })
      .catch(e => {
        rej(e)
      })
  })
}

export const fetchService = async (params, type) => {
  return await axios({
    method: 'GET',
    url: `${baseUrl}${type}`,
    headers: jsonHeaders(),
    params
  })
}

export const pdfService = async (data) => {
  return await axios({
    method: 'POST',
    url: `${baseUrl}pdf`,
    headers: jsonHeaders(),
    data
  })
}

export const deleteService = async (id, type) => {
  return await axios({
    method: 'DELETE',
    url: `${baseUrl}${type}/${id}`,
    headers: jsonHeaders()
  })
}

export const uploadService = async (file, type) => {
  var data = new FormData()
  data.append('image', file)

  return await axios({
    method: 'POST',
    url: `${baseUrl}upload/${type}`,
    headers: jsonHeaders(),
    data
  })
}

export const createService = async (data, type) => {
  return await axios({
    method: 'POST',
    url: `${baseUrl}${type}`,
    headers: jsonHeaders(),
    data
  })
}

export const updateService = async (data, type, id) => {
  return await axios({
    method: 'PATCH',
    url: `${baseUrl}${type}/${id}`,
    headers: jsonHeaders(),
    data
  })
}

export const fetchItemService = async (type, id) => {
  return await axios({
    method: 'GET',
    url: `${baseUrl}${type}/${id}`,
    headers: jsonHeaders()
  })
}

export const downloadService = async (pdf) => {
  return await axios({
    method: 'GET',
    url: `${baseUrl}download/${pdf}`,
    headers: jsonHeaders(),
    responseType: 'blob'
  })
}


export const deletePdfService = async (id) => {
  return await axios({
    method: 'DELETE',
    url: `${baseUrl}pdf?pdfId=${id}`,
    headers: jsonHeaders()
  })
}


